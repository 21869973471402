// Loader.js
import React from 'react';
import './loader.css';
import img from '../assets/images/android-chrome-512x512.png';

const Loader = () => {
    return (
        <div className="loader">
            <img className="loader__icon" src={img} alt="loader" />
        </div>
    );
};

export default Loader;
