import { BrowserRouter } from 'react-router-dom';
import AllRoutes from '.';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
const Routes = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
